import React from "react";

const MEASUREMENT_TYPES = { weight: "Weight", waist: "Waist" };

export default function AddMeasurementForm({
  onSubmit,
  onCancel,
  showCancel = true,
  disabled = false,
}) {
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [type, setType] = React.useState("none");
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (type !== "none" && value > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [type, value]);

  const handleTypeChange = (e) => setType(e.target.value);
  const handleValueChange = (e) => setValue(e.target.value);

  function handleSubmit(e) {
    e.preventDefault();

    onSubmit({ type, value });
  }

  return (
    <div>
      <form noValidate>
        <ul className="form-wrapper">
          <li className="form-row">
            <strong>Measurement Type *</strong>
            <select onBlur={handleTypeChange}>
              <option key={`measurement-type-none`} value={"none"}>
                Select type...
              </option>
              {Object.keys(MEASUREMENT_TYPES).map((key, index) => (
                <option key={`measurement-type-${key}`} value={key}>
                  {MEASUREMENT_TYPES[key]}
                </option>
              ))}
            </select>
          </li>
          <li className="form-row">
            <label htmlFor="value">
              <strong>
                Value{" "}
                {type !== "none" && (
                  <>({type === "weight" ? "lbs" : "inches"})</>
                )}{" "}
                *
              </strong>
            </label>
            <input
              name="value"
              value={value}
              onChange={handleValueChange}
              type="number"
              min="0"
              step="0.1"
              onFocus={(e) => {
                e.target.select();
              }}
            />
          </li>
          <li className="form-row">
            {!!showCancel && (
              <button className="fancy" onClick={onCancel}>
                Cancel
              </button>
            )}
            <button
              className="fancy"
              disabled={!canSubmit || disabled}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
}
