import React from "react";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle";
import { FaTimesCircle } from "@react-icons/all-files/fa/FaTimesCircle";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import { withFirebase } from "../../components/Firebase";
import { useAuthUser } from "../../components/Session";
import AddMeasurementForm from "./AddMeasurementForm";

function Measurements({ firebase }) {
  const authUser = useAuthUser();
  const [data, setData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [showAdd, setShowAdd] = React.useState(false);
  const [unsubscribe, setUnsubscribe] = React.useState();
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    if (firebase && !loaded) {
      const unsub = firebase
        .measurements(authUser.uid)
        .onSnapshot((snapshot) => {
          let dbMeasurements = [];

          snapshot.forEach((doc) => {
            dbMeasurements.push({ ...doc.data(), id: doc.id });
          });

          setData(dbMeasurements.sort((a, b) => b.date - a.date));
          setLoaded(true);
          setUnsubscribe(unsub);
        });
    }
    return () => unsubscribe;
  }, [firebase, loaded, unsubscribe, authUser]);

  let measurements = `You haven't recorded any measurements yet!`;
  if (data.length > 0) {
    measurements = data.map((item) => {
      const date = format(fromUnixTime(item.date), "MM/dd/yyyy");
      const type = item.type[0].toUpperCase() + item.type.slice(1);
      return (
        <div key={item.id}>
          ({date}) {type}: {item.value}{" "}
          {editing && (
            <button
              className="fancy small"
              onClick={() => {
                handleRemove(item.id);
              }}
            >
              <FaTrashAlt size={15} />
            </button>
          )}
        </div>
      );
    });
  }

  function handleRemove(id) {
    firebase
      .measurements(authUser.uid)
      .doc(id)
      .delete()
      .then(() => {
        setData(data.filter((item) => item.id !== id));
      })
      .catch(() => {
        // error deleting
      });
  }

  const handleSubmit = ({ type, value }) => {
    const date = getUnixTime(new Date());
    const newMeasurement = { type, date, value };

    firebase
      .measurements(authUser.uid)
      .add(newMeasurement)
      .then((docRef) => {
        setData([{ id: docRef.id, ...newMeasurement }, ...data]);
      })
      .catch((err) => {
        console.log("error posting", err);
      })
      .finally(() => {
        setShowAdd(false);
      });
  };

  return (
    <div>
      <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
        Measurements{" "}
        <button
          className="fancy small"
          onClick={() => setShowAdd(!showAdd)}
          style={{ marginRight: "1.5em" }}
        >
          {!showAdd ? <FaPlusCircle /> : <FaTimesCircle />}
        </button>
        <button className="fancy small" onClick={() => setEditing(!editing)}>
          {!editing ? <FaEdit /> : <FaTimesCircle />}
        </button>
      </h4>
      {showAdd && (
        <AddMeasurementForm
          onSubmit={handleSubmit}
          onCancel={() => setShowAdd(false)}
        />
      )}
      <div>
        {!loaded ? (
          <span>
            <FaSpinner className="fa-spin" /> Loading...
          </span>
        ) : (
          measurements
        )}
      </div>
    </div>
  );
}

export default withFirebase(Measurements);
